/// <reference types="@angular/localize" />

import {bootstrapApplication} from '@angular/platform-browser';
import {appConfig} from './app/app.config';
import {AppComponent} from './app/app.component';
import {SentryInitializer} from "./app/utils/sentry-initializer";

SentryInitializer.initialize();

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
