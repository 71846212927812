import * as SentryAngular from "@sentry/angular-ivy";
import {environment} from "../../environments/environment";
// @ts-ignore
import packageInfo from '../../../package.json';

export class SentryInitializer {
  public static initialize(): void {

    SentryAngular.init(
      {
        environment: environment.name,
        dsn: environment.sentryDsn,
        release: packageInfo.name + "@" + packageInfo.version,
        // Set your dist version, such as "1"
        dist: "1",
        integrations: [
          new SentryAngular.BrowserProfilingIntegration()
        ],
        tracesSampleRate: 1.0,
      }
    );
  }
}
