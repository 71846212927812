import {Routes} from '@angular/router';
import {authenticatedGuard} from "./guards/authenticated.guard";
import {unauthenticatedGuard} from "./guards/unauthenticated.guard";

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadComponent: () => import('./components/login/login.component').then(m => m.LoginComponent),
    canActivate: [unauthenticatedGuard]
  },
  {
    path: 'main',
    loadComponent: () => import('./components/main/main.component').then(m => m.MainComponent),
    canActivate: [authenticatedGuard],
  },

  {
    path: 'articles/:id',
    loadComponent: () => import('./components/main/articles/article/article.component').then(m => m.ArticleComponent),
    canActivate: [authenticatedGuard]
  },

  {
    path: 'people/reset-password',
    loadComponent: () => import('./components/people/reset-password/reset-password.component').then(m => m.ResetPasswordComponent),
    canActivate: [unauthenticatedGuard]
  }
];
