import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {Account} from "../../domain/account";
import {firstValueFrom} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AccountService extends BaseService {
  public async get(): Promise<Account | undefined> {
    return this.authenticationService.getAccount();
  }

  public async forgotPassword(email: string) {
    const body = {person: {email}};
    await firstValueFrom(this.http.post<void>(environment.backendUri + '/people/password', body));
  }

  public async updatePassword(token: string, password: string) {
    const body = {person: {reset_password_token: token, password: password}};
    await firstValueFrom(this.http.patch<void>(environment.backendUri + '/people/password', body));
  }
}
