import {Injectable} from '@angular/core';
import {AuthenticationService} from "./authentication/authentication.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {firstValueFrom, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor(protected authenticationService: AuthenticationService, protected http: HttpClient) {
  }

  protected async authenticatedRequest<T>(request: ((headers: HttpHeaders) => Observable<T>)): Promise<T> {
    const account = await this.authenticationService.getAccount();
    const headers = new HttpHeaders({Authorization: 'Bearer ' + account!.token});
    return firstValueFrom(request(headers));
  }
}
