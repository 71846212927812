import {autoserializeAs} from 'cerialize';

export class Account {
  @autoserializeAs('id')
  public id!: number;

  @autoserializeAs('token')
  public token!: string;

  @autoserializeAs('role')
  public role!: string;
}
