import {CanActivateFn, Router} from '@angular/router';
import {inject} from "@angular/core";
import {AccountService} from "../services/api/account.service";

export const unauthenticatedGuard: CanActivateFn = async (route, state) => {
  const accountService = inject(AccountService);
  const router = inject(Router);

  if (await accountService.get() == null){
    return true;
  }

  return router.createUrlTree(['/', 'main']);
};
