import {Injectable} from '@angular/core';
import {SessionStorageService} from "./session-storage.service";
import {Account} from "../../domain/account";

@Injectable({
  providedIn: 'root'
})
export class AccountStorageService extends SessionStorageService<Account> {
  constructor() {
    super(Account, 'account');
  }
}
