import {StorageService} from "./storage.service";
import {Deserialize, Serialize} from "cerialize";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export abstract class SessionStorageService<T> implements StorageService<T> {
  protected constructor(private type: any, private storageKey: string) {
  }

  public async init(): Promise<void> {
    return Promise.resolve();
  }

  public async get(): Promise<T> {
    const storedValue = sessionStorage.getItem(this.storageKey);
    if (storedValue == null){
      return Promise.reject(`key '${this.storageKey}' not found in session storage`);
    }

    return Promise.resolve(Deserialize(JSON.parse(storedValue), this.type));
  }

  public async set(value: any): Promise<void> {
    sessionStorage.setItem(this.storageKey, JSON.stringify(Serialize(value)));
    return Promise.resolve();
  }

  public async remove(): Promise<void> {
    sessionStorage.removeItem(this.storageKey);
    return Promise.resolve();
  }
}
