import {Injectable} from '@angular/core';
import {Account} from "../../../domain/account";
import {HttpClient} from "@angular/common/http";
import {Deserialize} from "cerialize";
import {firstValueFrom} from "rxjs";
import {environment} from "../../../../environments/environment";
import {AccountStorageService} from "../../persistence/account-storage.service";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  protected account?: Account;

  constructor(protected accountStorageService: AccountStorageService, protected http: HttpClient) {
  }

  public async getAccount(): Promise<Account | undefined> {
    if (!this.account) {
      await this.init();
    }

    return this.account;
  }

  public async login(email: string, password: string): Promise<Account> {
    const body = {person: {email, password}};
    const account = Deserialize(await firstValueFrom(this.http.post<Account>(environment.backendUri + '/people/sign_in', body)), Account);
    await this.accountStorageService.set(account);
    return account;
  }

  private async init(): Promise<void> {
    this.accountStorageService.get().then(account =>{
      this.account = account;
    }).catch(() => {
      this.account = undefined;
    });
  }
}
