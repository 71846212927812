import {APP_INITIALIZER, ApplicationConfig, ErrorHandler, LOCALE_ID} from '@angular/core';
import {provideRouter, Router} from '@angular/router';

import {routes} from './app.routes';
import {provideHttpClient} from "@angular/common/http";
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {registerLocaleData} from "@angular/common";

import localeNl from '@angular/common/locales/nl';
import {MatPaginatorIntl} from "@angular/material/paginator";
import {getMatPaginatorLocaleNl} from "./utils/mat-paginator-locale-nl";
import {provideIonicAngular} from "@ionic/angular/standalone";
import * as SentryAngular from '@sentry/angular-ivy';

registerLocaleData(localeNl, 'nl');

export const appConfig: ApplicationConfig = {
  providers: [
    provideIonicAngular({mode: 'ios'}),
    provideRouter(routes),
    provideHttpClient(),
    provideAnimationsAsync(),
    {provide: LOCALE_ID, useValue: 'nl-NL'},
    {provide: MatPaginatorIntl, useValue: getMatPaginatorLocaleNl()},
    {
      provide: ErrorHandler,
      useValue: SentryAngular.createErrorHandler({
        logErrors: true,
        showDialog: false,
      }),
    },
    {
      provide: SentryAngular.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [SentryAngular.TraceService],
      multi: true,
    },
  ]
};
